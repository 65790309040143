import axios from "axios";

const axiosInstance = axios.create({
  baseURL: 'https://4k4o2eikk8.execute-api.ap-northeast-1.amazonaws.com/prod/rema',
});

axiosInstance.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem("access_token");
  config.headers.Authorization =  'Bearer '+  token;
  return config 
});

export default axiosInstance